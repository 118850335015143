@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #F5EFED;
    --brg: #1C3738;
    --guards: #D00000;
    --tangerine: #FA7921;
    --speed: #FEB829;
    --black: #000F08;
    --background-dark: #121212;
    --background-light: #303030;
    --background-alt: #283dff;
    --foreground: #e0e0e0;
    --foreground-dark: #cccccc;
    --foreground-light: #f0f0f0;
    --foreground-alt: #101010;
    --border: #404040;
    --input-border: #606060;
    --input: #cccccc;
    --header: #101010;
    --footer: #101010;
    --segment: #f0f0f0;
    --ring: #08dee3;
    --muted: #404040;
    --muted-foreground: #808080;
    --accent: #0779c6;
    --accent-foreground: #000000;
    --primary: #232323;
    --primary-foreground: #cccccc;
    --secondary: #DDD8D6;
    --secondary-foreground: #1C3738;
    --warning: #ff0000;
    --warning-foreground: #e0e0e0;
    --active: #0b8ef1;
    --border: #aaaaaa;
    --green: #85C27D;

    /* buttons */
    --button-primary-bg: #08dee3;
    --button-primary-fg: #000000;
    --button-primary-bg-hover: #0b8ef1;
    --button-primary-fg-hover: #000000;

    --button-secondary-bg: #999999;
    --button-secondary-fg: #000000;
    --button-secondary-bg-hover: #eeeeee;
    --button-secondary-fg-hover: #000000;

    --button-outline-bg: #08dee3;
    --button-outline-fg: #cccccc;
    --button-outline-bg-hover: #0b8ef1;
    --button-outline-fg-hover: #000000;

    --button-destructive-bg: #ff0000;
    --button-destructive-fg: #ffffff;
    --button-destructive-bg-hover: #808080;
    --button-destructive-fg-hover: #000000;

    --button-ghost-bg: #333333;
    --button-ghost-fg: #777777;
    --button-ghost-bg-hover: #777777;
    --button-ghost-fg-hover: #aaaaaa;

    --button-link-bg: #08dee3;
    --button-link-fg: #eeeeee;
    --button-link-bg-hover: #08dee3;
    --button-link-fg-hover: #000000;

    /* badges */
    --badge-listing: #00ff3f;
    --badge-listing-foreground: #000000;

    --badge-want: #f1184c;
    --badge-want-foreground: #000000;

    --badge-garage: #ff479c;
    --badge-garage-foreground: #000000;

    --badge-event: #fffb38;
    --badge-event-foreground: #000000;

    --badge-group: #f89cfa;
    --badge-group-foreground: #000000;

    --badge-record: #35b5ff;
    --badge-record-foreground: #000000;

    --badge-default: #f0d689;
    --badge-default-foreground: #000000;

    --badge-spot: #f36943;
    --badge-spot-foreground: #000000;

    --badge-update: #2e9599;
    --badge-update-foreground: #ffffff;
  }
}

html,
body {
  scroll-behavior: smooth;
}

.main-page {
  min-height: calc(100vh - 85px);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-background text-black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primaryColor: rgb(122, 198, 198);
  --secondaryColor: magenta;
  --tertiaryColor: gold;
  --successColor: green;
  --errorColor: red;
}

ul li {
  /* Remove display: block; or change to display: list-item; */
  display: list-item;
  margin-left: 15px;
}

ul {
  list-style-type: disc !important;
  /* Add padding to provide space for the bullets */
  padding-left: 20px;
}
.nav-item[aria-current="page"] {
  color: var(--secondaryColor);
}

.nav-item {
  display: block;
}

.expand {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__slide.peek {
  position: relative;
  flex: 0 0 80% !important; /* Adjust the width to allow peeking */
  margin-right: 15px !important; /* Space between slides */
  margin-left: 15px !important;
}

.embla {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  align-items: center;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
  opacity: 0.2;
}
.embla__button__svg {
  width: 30%;
  height: 30%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
  border: 1px solid #404040;
  transition: all 0.2s ease-out;
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: #404040;
}

.spinner {
  padding: 0.6rem 0;
}

.spinner-circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  padding: 0.5rem 1rem;
}

.spinner-circle:before {
  z-index: 5;
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 2px dashed #000;
  width: 20px;
  height: 20px;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  animation: loading 1s linear infinite;
}

.spinner-circle:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 2px dashed #000;
  width: 20px;
  height: 20px;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  animation: loading 1s ease infinite;
  z-index: 10;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary) inset !important;
  -webkit-text-fill-color: var(--secondary) !important;
  border: 1px solid hsl(var(--input-border));
}

#root {
  .rsw-toolbar {
    background-color: var(--secondary);
    border-bottom: 1px solid var(--input-border);
  }

  .rsw-separator {
    border-right: 1px solid var(--input-border);
  }

  .rsw-editor {
    border: 1px solid var(--input-border);
  }

  .rsw-ce:focus {
    outline: 1px solid var(--input-border);
  }

  .rsw-btn {
    color: var(--brg);

    &:hover {
      background: var(--input-border);
    }
  }

  .rsw-dd {
    background: var(--input-border);
    padding: 3px;
    border-radius: 5px;
  }

  .rsw-ce {
    min-height: 200px;
    background: var(--secondary);
  }

  .article-wysiwyg .rsw-ce {
    height: 1000px;
  }
}

.banner-image {
  img {
    object-fit: cover;
    background-position: center center;
    max-height: 350px;
  }
}

.article-wysiwyg {
  max-width: 40em; /* ~75 characters per line */
  margin: 3rem auto;
  padding: 0 1rem;
}

.article-wysiwyg, .ors-rich-text {

  font-family: 'Literata', serif;
  font-size: 19px;
  line-height: 1.7;

  &.full {
    max-width: 100%;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Literata', serif;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  h1 { font-size: 2.5rem; line-height: 1.3; }
  h2 { font-size: 2rem; }
  h3 { font-size: 1.75rem; }
  h4 { font-size: 1.5rem; }
  h5 { font-size: 1.25rem; }
  h6 { font-size: 1.1rem; }
  
  p {
    margin-bottom: 1.5rem;
  }
  
  strong {
    font-weight: 700;
  }
  
  em {
    font-style: italic;
  }
  
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  li {
    margin-bottom: 0.5rem;
  }
  
  blockquote {
    font-style: italic;
    font-size: 1.3rem;
    border-left: 4px solid #888;
    padding-left: 1rem;
    margin: 2rem 0;
    color: #555;
  }
  
  code {
    font-family: 'Courier New', monospace;
    background: #F5F5F5;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  pre {
    background: #F5F5F5;
    padding: 1rem;
    border-radius: 5px;
    overflow-x: auto;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  img {
    max-width: 100%;
    display: block;
    margin: 1.5rem auto;
    border-radius: 5px;
  }
  
  hr {
    border: none;
    border-top: 2px solid #DDD;
    margin: 3rem 0;
  }
  
  a {
    color: var(--guards);
    text-decoration: underline;
  }
  
  a:hover {
    text-decoration: underline;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: black !important; /* Ensures text remains black */
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* Forces background to be white */
  transition: background-color 5000s ease-in-out 0s; /* Prevents autofill color flashing */
  
  /* Add a border or background color */
  background-color: #ffffff !important; /* Light gray background for autofill */
  border: 1px solid #333 !important; /* Light gray border */
}